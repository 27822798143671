import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Drawer,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";

const StyledButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#7366FF",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#5A52D6",
  },
});

const Batches = () => {
  const [rows, setRows] = useState([
    {
      id: 1,
      batchname: "Batch A",
      image: "https://via.placeholder.com/100", // Replace with actual image URLs
      class: "12",
      subject: "Mathematics",
      price: "Rs1500",
      startdate: "2023-12-01",
      enddate: "2024-03-01",
      laguage: "English",
      teacher: "John Doe",
      category: "JEE Mains",
    },
    {
      id: 2,
      batchname: "Batch B",
      image: "https://via.placeholder.com/100",
      class: "12",
      subject: "Physics",
      price: "Rs1800",
      startdate: "2023-11-15",
      enddate: "2024-02-15",
      laguage: "English",
      teacher: "Jane Smith",
      category: "NEET",
    },
    {
      id: 3,
      batchname: "Batch C",
      image: "https://via.placeholder.com/100",
      class: "12",
      subject: "Chemistry",
      price: "Rs2000",
      startdate: "2023-10-01",
      enddate: "2023-12-20",
      laguage: "Hindi",
      teacher: "Emily Davis",
      category: "JEE Advanced",
    },
    {
      id: 4,
      batchname: "Batch D",
      image: "https://via.placeholder.com/100",
      class: "12",
      subject: "Biology",
      price: "Rs1700",
      startdate: "2023-09-15",
      enddate: "2023-12-10",
      laguage: "English",
      teacher: "Michael Brown",
      category: "NEET",
    },
    {
      id: 5,
      batchname: "Batch E",
      image: "https://via.placeholder.com/100",
      class: "12",
      subject: "History",
      price: "Rs1400",
      startdate: "2023-08-01",
      enddate: "2023-11-30",
      laguage: "Hindi",
      teacher: "Sarah Johnson",
      category: "NEET",
    },
  ]);
  
    
      const [searchTerm, setSearchTerm] = useState("");
      const [isDrawerOpen, setIsDrawerOpen] = useState(false);
      const [selectedRow, setSelectedRow] = useState(null);
    
      const isMobile = useMediaQuery("(max-width:600px)");
    
      // Updated filtering logic
      const filteredRows = rows.filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    
 
  const handleEditRow = (row) => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleSaveChanges = () => {
    if (selectedRow.id) {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === selectedRow.id ? selectedRow : row))
      );
    } else {
      const newId = rows.length + 1;
      setRows([
        ...rows,
        {
          ...selectedRow,
          id: newId,
          createdAt: new Date().toISOString().split("T")[0],
        },
      ]);
    }
    setSelectedRow(null);
    setIsDrawerOpen(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };
  const navigate = useNavigate()
  const handlenavigate =() =>{
    navigate('/create-batch')
  }



  const columns = [
        { field: "id", headerName: "SL", flex: 0.3 },
        { field: "batchname", headerName: "Batch Name", flex: 1 },
        { field: "image", headerName: "Images", flex: 1 },
        { field: "class", headerName: "Class", flex: 1 },
        { field: "subject", headerName: "Subject", flex: 1 },
        { field: "price", headerName: "Price", flex: 1 },
        { field: "startdate", headerName: "Start Date", flex: 1 },
        { field: "enddate", headerName: "End Date", flex: 1 },
        { field: "laguage", headerName: "Language", flex: 1 },
        { field: "teacher", headerName: "Teacher", flex: 1 }, 
        { field: "category", headerName: "Category", flex: 1 },
       
        // { field: "questiontitle", headerName: "Question Title", flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {/* View Icon */}
                <Link to={`/batch-details/${params.row.id}`}>
                  <EyeIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#2563eb",
                      cursor: "pointer",
                    }}
                    title="View Details"
                  />
                </Link>
          
                {/* Edit Icon */}
                <PencilSquareIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#10b981",
                    cursor: "pointer",
                  }}
                  title="Edit"
              
                />
          
                {/* Delete Icon */}
                <TrashIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#ef4444",
                    cursor: "pointer",
                  }}
                  title="Delete"
                
                />
              </Box>
            ),
          },
  ];

  return (
    <div
      style={{
        fontFamily: "Poppins",
        padding: isMobile ? "10px" : "15px",
      }}
    >
       <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2b3674",
          }}
        >
          Batch Creation
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: isMobile ? "100%" : 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlassIcon
                    style={{ width: "20px", height: "20px", color: "#7366FF" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            onClick={handlenavigate}
            sx={{ width: isMobile ? "100%" : "auto" }}
          >
            Create Batch
          </StyledButton>
        </Box>
      </Box>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={
            isMobile ? columns.filter((col) => col.field !== "number") : columns
          }
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div
          style={{
            width: isMobile ? "100vw" : 400,
            padding: "20px",
            fontFamily: "Poppins",
          }}
        >
          <h3>{selectedRow?.id ? "Edit Admin" : "Add New Admin"}</h3>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.name || ""}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
          <TextField
            label="Alias Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.aliasName || ""}
            onChange={(e) => handleInputChange("aliasName", e.target.value)}
          />
          <TextField
            label="Number"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.number || ""}
            onChange={(e) => handleInputChange("number", e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            margin="normal"
            value={selectedRow?.password || ""}
            onChange={(e) => handleInputChange("password", e.target.value)}
          />
          <TextField
            label="Subject"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.subject || ""}
            onChange={(e) => handleInputChange("subject", e.target.value)}
          />
          <TextField
            label="Experience"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.experience || ""}
            onChange={(e) => handleInputChange("experience", e.target.value)}
          />
          <TextField
            label="Address"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.address || ""}
            onChange={(e) => handleInputChange("address", e.target.value)}
          />
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedRow?.roles || []}
              onChange={(e) => handleInputChange("roles", e.target.value)}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      style={{
                        backgroundColor: "#7366FF",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
              )}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Teacher">Teacher</MenuItem>
            </Select>
          </FormControl>
          <StyledButton
            fullWidth
            onClick={handleSaveChanges}
            sx={{ marginTop: "20px" }}
          >
            Save Changes
          </StyledButton>
        </div>
      </Drawer>
    </div>
  );
};

export default Batches;
