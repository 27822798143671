import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  InputLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CreateBatch = () => {
  // State for managing form data
  const [formData, setFormData] = useState({
    batchName: "",
    startDate: "",
    endDate: "",
    targetYear: "",
    maxStudents: "",
    batchFee: "",
    class: "",
    contentCategory: "",
    language: "",
    teachers: [],
    imageFile: null,
  });

  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const fileInputRef = useRef(null);

  // Dropdown options
  const classOptions = ["11th", "12th"];
  const subjectOptions = ["Physics", "Chemistry", "Math"];
  const languageOptions = ["English", "Hindi", "Spanish"];
  const teacherOptions = ["John Doe", "Jane Smith", "Emily Davis"];

  // Handle input changes
  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedSubjects((prev) => [...prev, name]);
    } else {
      setSelectedSubjects((prev) => prev.filter((subject) => subject !== name));
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prev) => ({ ...prev, imageFile: file }));
  };

  // Form submission handler
  const handleSubmit = () => {
    console.log("Form Data:", formData);
    console.log("Selected Subjects:", selectedSubjects);
  };

  // Mock data for teacher assignment table
  const rows = selectedSubjects.map((subject, index) => ({
    id: index + 1,
    subject,
    teacher: teacherOptions[index % teacherOptions.length], // Mock teacher names
  }));

  const columns = [
    { field: "id", headerName: "SL", flex: 0.3 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "teacher", headerName: "Assigned Teacher", flex: 1 },
  ];

  return (
    <Box sx={{ p: 4 }}>
      {/* Batch Details Section */}
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        sx={{
          marginBottom: 3,
          textTransform: "none",
          fontWeight: "bold",
          color: "#2563eb",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Back to Batch List
      </Button>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Batch Name"
            fullWidth
            InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
            value={formData.batchName}
            onChange={(e) => handleChange("batchName", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Batch Start Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.startDate}
            InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
            onChange={(e) => handleChange("startDate", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Batch End Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.startDate}
            InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
            onChange={(e) => handleChange("startDate", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
            label="Target Years"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.startDate}
            InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
            onChange={(e) => handleChange("startDate", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>Class</InputLabel>
          <Select
            fullWidth
            value={formData.class}
            onChange={(e) => handleChange("class", e.target.value)}
          >
            {classOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} mt={3}>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleFileClick}
            style={{padding:"16px"}}
            
          >
            Upload Batch Banner Image
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
          />
        </Grid>
      </Grid>

      {/* Teacher Assignment Section */}
      <Typography variant="h5" mt={4} style={{fontSize:"20px",fontWeight:"600"}}>
        Teacher for Subjects
      </Typography>
      <FormGroup row>
        {subjectOptions.map((subject) => (
          <FormControlLabel
            key={subject}
            control={
              <Checkbox
                name={subject}
                onChange={handleCheckboxChange}
                checked={selectedSubjects.includes(subject)}
              />
            }
            label={subject}
          />
        ))}
      </FormGroup>
      {selectedSubjects.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Assigned Teachers
          </Typography>
          <DataGrid rows={rows} columns={columns} autoHeight style={{width:'40%'}}/>
        </Box>
      )}

 
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Content Category"
            fullWidth
            value={formData.contentCategory}
            onChange={(e) => handleChange("contentCategory", e.target.value)}
            select
          >
            <MenuItem value="JEE Mains">JEE Mains</MenuItem>
            <MenuItem value="NEET">NEET</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleFileClick}
            style={{padding:"15px"}}
          >
            Upload Excel
          </Button>
        </Grid>
      </Grid>

      {/* Additional Info Section */}
      {/* <Typography variant="h5" mt={4}>
        Additional Info
      </Typography> */}
      <Grid container spacing={3}  mt={3}>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            label="Max. Students Allowed"
            fullWidth
            value={formData.maxStudents}
            onChange={(e) => handleChange("maxStudents", e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Batch Fee"
            fullWidth
            value={formData.batchFee}
            onChange={(e) => handleChange("batchFee", e.target.value)}
            InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
              select
              label="Select a Language"
              // value={selectedSubject}
              // onChange={(e) => setSelectedSubject(e.target.value)}
              fullWidth
            >
              <MenuItem value="Physics">English</MenuItem>
              <MenuItem value="Hindi">Hindi</MenuItem>
            </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="About Batch "
            placeholder="Enter a detailed description about the batch"
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
            variant="outlined"
          />
        </Grid>
      </Grid>

      {/* Submit Button */}
      <StyledButton
            fullWidth={70}
            
            sx={{ marginTop: "20px" }}
          >
            Save
          </StyledButton>
    </Box>
  );
};

export default CreateBatch;
const StyledButton = styled(Button)({
    fontFamily: "Poppins",
    backgroundColor: "#7366FF",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5A52D6",
    },
  });