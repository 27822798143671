import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "12px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  marginBottom: theme.spacing(3),
  fontFamily: "'Poppins', sans-serif",
}));

const QuestionDetails = () => {
  const { id } = useParams(); // Get question ID from URL

  // Example Question Data
  const questionInfo = {
    questionCode: "Q001",
    class: "12th",
    subject: "Mathematics",
    difficultyLevel: "Medium",
    questionType: "Multiple Choice",
    questionLanguage: "English",
    questionTitle: "What is the value of π?",
    questionSection: "This section explains the properties of π.",
    questionAnswer: "The value of π is approximately 3.14159.",
    optionsText: ["3.14", "3.141", "3.14159", "3.2"],
    numberOfOptions: 4,
    correctAnswer: "3.14159",
    status: "Active",
  };

  return (
    <Box sx={{ padding: 4, fontFamily: "'Poppins', sans-serif" }}>
      {/* Back Button */}
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        sx={{
          marginBottom: 3,
          textTransform: "none",
          fontWeight: "bold",
          color: "#2563eb",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Back to Question List
      </Button>

      {/* Header */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: 3,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Question Details
      </Typography>

      {/* Question Info Section */}
      <StyledPaper>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Question Code:</strong> {questionInfo.questionCode}
            </Typography>
            <Typography mt={2}>
              <strong>Class:</strong> {questionInfo.class}
            </Typography>
            <Typography mt={2}>
              <strong>Subject:</strong> {questionInfo.subject}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Difficulty Level:</strong> {questionInfo.difficultyLevel}
            </Typography>
            <Typography mt={2}>
              <strong>Question Type:</strong> {questionInfo.questionType}
            </Typography>
            <Typography mt={2}>
              <strong>Question Language:</strong> {questionInfo.questionLanguage}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Question Title:</strong> {questionInfo.questionTitle}
            </Typography>
            <Typography mt={2}>
              <strong>Question Section:</strong> {questionInfo.questionSection}
            </Typography>
            <Typography mt={2}>
              <strong>Question Answer:</strong> {questionInfo.questionAnswer}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Options Text:</strong>{" "}
              {questionInfo.optionsText.join(", ")}
            </Typography>
            <Typography mt={2}>
              <strong>Number Of Options:</strong> {questionInfo.numberOfOptions}
            </Typography>
            <Typography mt={2}>
              <strong>Correct Answer:</strong> {questionInfo.correctAnswer}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2 }}>
          <Chip
            label={questionInfo.status}
            color={questionInfo.status === "Active" ? "success" : "default"}
            sx={{
              fontWeight: "bold",
              padding: "5px 10px",
              fontFamily: "'Poppins', sans-serif",
            }}
          />
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default QuestionDetails;
