import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Drawer,
  TextField,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { EditNotifications } from "@mui/icons-material";

const StyledButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#7366FF",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#5A52D6",
  },
});

const Template = () => {
  const [rows, setRows] = useState([
    {
      id: 1,
      templateName: "Math Test",
      testDuration: "60 minutes",
      marks: 100,
      description: "This is a comprehensive math test.",
      sections: "Algebra, Geometry",
      noOfQuestions: 50,
    },
    {
      id: 2,
      templateName: "Science Test",
      testDuration: "45 minutes",
      marks: 80,
      description: "Science test covering basic concepts.",
      sections: "Physics, Chemistry",
      noOfQuestions: 40,
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const filteredRows = rows.filter((row) =>
    row.templateName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleSaveChanges = () => {
    if (selectedRow.id) {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === selectedRow.id ? selectedRow : row))
      );
    } else {
      const newId = rows.length + 1;
      setRows([
        ...rows,
        {
          ...selectedRow,
          id: newId,
        },
      ]);
    }
    setSelectedRow(null);
    setIsDrawerOpen(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };

  const columns = [
    { field: "id", headerName: "SL", flex: 0.3 },
    { field: "templateName", headerName: "Template Name", flex: 1 },
    { field: "testDuration", headerName: "Test Duration", flex: 1 },
    { field: "marks", headerName: "Marks", flex: 1 },
    // { field: "description", headerName: "Description", flex: 2 },
    { field: "sections", headerName: "Sections", flex: 1 },
    { field: "noOfQuestions", headerName: "No. of Questions", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {/* Edit Icon */}
          <PencilSquareIcon
            onClick={() => handleEditRow(params.row)}
            style={{
              marginTop:"20px",
              width: "20px",
              height: "20px",
              color: "#10b981",
              cursor: "pointer",
            }}
            title="Edit"
         />
    
          {/* Delete Icon */}
          <TrashIcon
           onClick={() =>
            setRows((prevRows) =>
              prevRows.filter((row) => row.id !== params.row.id)
            )
          }
            style={{
              marginTop:"20px",
              width: "20px",
              height: "20px",
              color: "#ef4444",
              cursor: "pointer",
            }}
            title="Delete"
          />
        
        </Box>
      ),
      sortable: false,
    }
  ];

  return (
    <div
      style={{
        fontFamily: "Poppins",
        padding: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2b3674",
          }}
        >
          Test Templates
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            placeholder="Search template..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlassIcon
                    style={{ width: "20px", height: "20px", color: "#7366FF" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            onClick={() => {
              setSelectedRow({
                templateName: "",
                testDuration: "",
                marks: "",
                description: "",
                sections: "",
                noOfQuestions: "",
              });
              setIsDrawerOpen(true);
            }}
          >
            Create Template
          </StyledButton>
        </Box>
      </Box>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div
          style={{
            width: 400,
            padding: "20px",
            fontFamily: "Poppins",
          }}
        >
          <h3>{selectedRow?.id ? "Edit Template" : "Add New Template"}</h3>
          <TextField
            label="Template Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.templateName || ""}
            onChange={(e) => handleInputChange("templateName", e.target.value)}
          />
          <TextField
            label="Test Duration"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.testDuration || ""}
            onChange={(e) => handleInputChange("testDuration", e.target.value)}
          />
          <TextField
            label="Marks"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            type="number"
            value={selectedRow?.marks || ""}
            onChange={(e) => handleInputChange("marks", e.target.value)}
          />
          <TextField
            label="Description"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            multiline
            rows={3}
            value={selectedRow?.description || ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
          <TextField
            label="Sections"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.sections || ""}
            onChange={(e) => handleInputChange("sections", e.target.value)}
          />
          <TextField
            label="No. of Questions"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            type="number"
            value={selectedRow?.noOfQuestions || ""}
            onChange={(e) => handleInputChange("noOfQuestions", e.target.value)}
          />
          <StyledButton
            fullWidth
            onClick={handleSaveChanges}
            sx={{ marginTop: "20px" }}
          >
            Save Template
          </StyledButton>
        </div>
      </Drawer>
    </div>
  );
};

export default Template;
