import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Paper,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});



const TestCreationN = () => {
  const [step, setStep] = useState(1);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [rows, setRows] = useState([
    {
      id: 1,
      templateName: "Math Test - 1",
      testDuration: "60",
      numSections: "3",
      numberofquestions: "100",
    },
    {
      id: 2,
      templateName: "Physics Quiz",
      testDuration: "45",
      numSections: "2",
      numberofquestions: "50",
    },
    {
      id: 3,
      templateName: "English Mock Test",
      testDuration: "90",
      numSections: "4",
      numberofquestions: "200",
    },
  ]);
  const [editingSection, setEditingSection] = useState(null);

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  // const navigate = useNavigate()

  // const handleNavigate=()=>{
  //   navigate('/TCreationlist')
  // }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const handleEdit = (section) => {
    setEditingSection(section);
  };

  const handleDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const tabs = [
    { id: 1, label: "Create Sections" },
    { id: 2, label: "Add Questions" },
    { id: 3, label: "Section Details" },
    { id: 4, label: "Preview" },
  ];
  const [step1, setStep1] = useState();

  const tabs1 = [
    { id: 1, label: "Physics" },
    { id: 2, label: "Chemistry" },
    { id: 3, label: "Math" },
  ];

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    { field: "templateName", headerName: "Test Template Name", flex: 1 },
    { field: "testDuration", headerName: "Test Duration (mins)", flex: 1 },
    { field: "numSections", headerName: "Sections", flex: 1 },
    {
      field: "numberofquestions",
      headerName: "Number of Questions",
      flex: 1,
    },
  ];

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };



  const [selectedFile, setSelectedFile] = useState(null);



  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      console.log("Uploaded file:", selectedFile.name);
      // Add logic to upload the file to the server or process it
    } else {
      console.log("No file selected.");
    }
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 3, backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#333" }}
        >
          Test Creation
        </Typography>

        <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
          {tabs.map((tab) => (
            <Button
              key={tab.id}
              variant={step === tab.id ? "contained" : "outlined"}
              onClick={() => setStep(tab.id)}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: 2,
              }}
            >
              {tab.label}
            </Button>
          ))}
        </Box>

        {step === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                All Sections
              </Typography>
              {rows.map((section) => (
                <Card
                  key={section.id}
                  sx={{
                    borderRadius: 2,
                    marginBottom: 2,
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        Section {section.id}
                      </Typography>
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() => handleEdit(section)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDelete(section.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      Section Title: {section.templateName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Question Type: Single Choice Questions
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      No. of Questions: {section.numberofquestions}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Min./Max Questions: 2/8
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: 2,
                  padding: 3,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#333" }}
                >
                  Configure Test Sections
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Section Name"
                      variant="outlined"
                      size="small"
                      value={editingSection?.templateName || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Question Type</InputLabel>
                      <Select defaultValue="">
                        <MenuItem value="single">Single Choice</MenuItem>
                        <MenuItem value="multiple">Multiple Choice</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Marks for Correct Answer"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Negative Marking (Optional)"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Min. Questions to be Answered"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Marks per Section"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 3,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setStep(2)}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "none",
                      padding: "6px 16px",
                    }}
                  >
                    Save Section
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setStep(2)}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "none",
                      padding: "6px 16px",
                    }}
                  >
                    Save Section & Add Another
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      fontWeight: "bold",
                      textTransform: "none",
                      padding: "6px 16px",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        )}

        {step === 2 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Curriculum Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Class</InputLabel>
                  <Select defaultValue="">
                    <MenuItem value="11th">11th</MenuItem>
                    <MenuItem value="12th">12th</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Subject</InputLabel>
                  <Select defaultValue="">
                    <MenuItem value="math">Math</MenuItem>
                    <MenuItem value="physics">Physics</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <Button variant="outlined" onClick={handleOpen} sx={{ mr: 2 }}>
                Get Questions from Question Bank
              </Button>
              <Button variant="outlined" onClick={handleOpen1}>
                Upload From File
              </Button>
            </Box>
          </Box>
        )}

        {step === 3 && (
        <Box>
       
  
        <div className="row">
          <div>
      
            <div className="fuiw mb-3" style={{display:'flex',gap:"20px"}}>
              <div className="fhd" style={{ width: "fit-content", }}>
                <div>SQC</div>
                <div>Total Questions: 10</div>
                <div>
                  Status: <span style={{ color: "green" }}>Complete</span>
                </div>
              </div>
              <div className="fhd" style={{ width: "fit-content" }}>
                <div>SQC</div>
                <div>Total Questions: 15</div>
                <div>
                  Status: <span style={{ color: "green" }}>Complete</span>
                </div>
              </div>
            </div>
  
            <div className="col-md-offset-3">
              <div className="tab" role="tabpanel">
                {/* Nav tabs */}
                <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
          {tabs1.map((tab) => (
            <Button
              key={tab.id}
              variant={step === tab.id ? "contained" : "outlined"}
              onClick={() => setStep1(tab.id)}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: 2,
              }}
            >
              {tab.label}
            </Button>
          ))}
        </Box>
  
                <div className="row">
                  <div className="col-md-8">
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>Q-8 41-486-19778</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          In comparison with the electrostatic force between two electrons, the electrostatic force between two protons is:
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>Q-9 41-486-19778</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        <Typography>Q-10 41-486-19778</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
  
                  <div className="col-md-4 mt-3"></div>
                  <div className="col-md-3 mt-3">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setStep(2)}
                      sx={{ marginRight: 2 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setStep(4)}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
        )}

        {step === 4 && (
          <Box >
          <Typography variant="h5" sx={{ mb: 3, fontWeight: "600", color: "#283563" }}>
            Test Details
          </Typography>
          <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
            {/* Test Information */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Title
                </Typography>
                <Typography sx={{ color: "#283563" }}>DEMO the 20</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Duration
                </Typography>
                <Typography sx={{ color: "#283563" }}>40 minutes</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Total Marks
                </Typography>
                <Typography sx={{ color: "#283563" }}>40</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Class
                </Typography>
                <Typography sx={{ color: "#283563" }}>12th</Typography>
              </Grid>
            </Grid>
    
            <Box sx={{ borderBottom: "1px solid #ccc", my: 2 }} />
    
            {/* Additional Details */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Test Type
                </Typography>
                <Typography sx={{ color: "#283563" }}>ONLINE</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Status
                </Typography>
                <Typography sx={{ color: "#283563" }}>Incomplete</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel>Print Questions Paper</InputLabel>
                  <Select defaultValue="row1">
                    <MenuItem value="row1">1 question per row</MenuItem>
                    <MenuItem value="row2">2 questions per row</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            
            </Grid>
    
            <Box sx={{ borderBottom: "1px solid #ccc", my: 2 }} />
    
            {/* Section Details */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                  Section Details
                </Typography>
                <Typography sx={{ color: "#283563" }}>Algebra, Geometry</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: "600", mb: 1 }}>
                  Upload Test Banner Image for App
                </Typography>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleFileClick}
                  sx={{ borderColor: "#283563", color: "#283563" }}
                >
                  + Upload jpg.file (350px x 150px)
                </Button>
                <input type="file" ref={fileInputRef} style={{ display: "none" }} />
              </Grid>
            </Grid>
          </Paper>
    
          <Typography variant="h5" sx={{ mt: 4, mb: 3, fontWeight: "600", color: "#283563" }}>
            Batch Details
          </Typography>
          <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
            <Tabs
              variant="fullWidth"
              sx={{
                backgroundColor: "#283563",
                color: "#fff",
                borderRadius: 1,
              }}
            >
              <Tab label="Batch ID"  style={{color:"white"}}/>
              <Tab label="Batch Name" style={{color:"white"}}/>
              <Tab label="Year" style={{color:"white"}}/>
            </Tabs>
          </Paper>
    
          {/* Navigation Buttons */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#283563", color: "#fff", textTransform: "none" }}
              onClick={() => console.log("Back")}
            >
              Back
            </Button>
            <Button
           
              variant="contained"
              sx={{ backgroundColor: "#283563", color: "#fff", textTransform: "none" }}
              // onClick={handleNavigate()}
            >
              Next
            </Button>
          </Box>
        </Box>
        )}

        {/* Modals */}
        <Modal open={open} onClose={handleClose}>
          <Box sx={modalStyle}>
            <Typography variant="h6">
              Get Questions from Question Bank
            </Typography>
            <TextField
            type="file"
            fullWidth
            onChange={handleFileChange}
            sx={{ mt: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </Box>
        </Box>
        </Modal>

        <Modal open={open1} onClose={handleClose1}>
          <Box sx={modalStyle}>
            <Typography variant="h6">Upload Questions</Typography>
            <Button variant="contained" sx={{ mt: 2 }} onClick={handleClose1}>
              Close
            </Button>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default TestCreationN;
