import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "12px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  marginBottom: theme.spacing(3),
  fontFamily: "'Poppins', sans-serif",
}));

const BatchDetails = () => {
  const { id } = useParams(); // Get batch ID from URL

  // Example Batch Data
  const batchInfo = {
    batchName: "Batch A - Algebra",
    class: "12th",
    startDate: "2023-12-01",
    endDate: "2024-03-01",
    targetYear: "2024",
    bannerImage: "https://via.placeholder.com/150", // Placeholder image URL
    teachers: [
      { name: "John Doe", subject: "Mathematics" },
      { name: "Jane Smith", subject: "Physics" },
      { name: "Emily Davis", subject: "Chemistry" },
    ],
    category: "Mathematics",
    fee: "Rs1500",
    language: "English",
    description:
      "This batch focuses on advanced algebra topics for Class 12 students, preparing them for competitive exams and school finals.",
    status: "Active",
  };

  return (
    <Box sx={{ padding: 4, fontFamily: "'Poppins', sans-serif" }}>
      {/* Back Button */}
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        sx={{
          marginBottom: 3,
          textTransform: "none",
          fontWeight: "bold",
          color: "#2563eb",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Back to Batch List
      </Button>

      {/* Header */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: 3,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Batch Details
      </Typography>

      {/* Batch Info Section */}
      <StyledPaper>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Batch Name:</strong> {batchInfo.batchName}
            </Typography>
            <Typography mt={2}>
              <strong>Class:</strong> {batchInfo.class}
            </Typography>
            <Typography mt={2}>
              <strong>Batch Start Date:</strong> {batchInfo.startDate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Batch End Date:</strong> {batchInfo.endDate}
            </Typography>
            <Typography mt={2}>
              <strong>Target Year:</strong> {batchInfo.targetYear}
            </Typography>
            <Typography mt={2}>
              <strong>Banner Image:</strong>
              <br />
              <img
                src={batchInfo.bannerImage}
                alt="Batch Banner"
                style={{
                  marginTop: "8px",
                  width: "150px",
                  height: "80px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Category:</strong> {batchInfo.category}
            </Typography>
            <Typography mt={2}>
              <strong>Batch Fee:</strong> {batchInfo.fee}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mt={2}>
              <strong>Language:</strong> {batchInfo.language}
            </Typography>
            <Typography mt={2}>
              <strong>About The Batch:</strong> {batchInfo.description}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2 }}>
          <Chip
            label={batchInfo.status}
            color={batchInfo.status === "Active" ? "success" : "default"}
            sx={{
              fontWeight: "bold",
              padding: "5px 10px",
              fontFamily: "'Poppins', sans-serif",
            }}
          />
        </Box>
      </StyledPaper>

      {/* Teachers Section */}
      <StyledPaper>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Teachers and Subjects
        </Typography>
        <List>
          {batchInfo.teachers.map((teacher, index) => (
            <ListItem key={index} disablePadding>
              <ListItemText
                primary={
                  <Typography>
                    <strong>Teacher:</strong> {teacher.name}
                  </Typography>
                }
                secondary={
                  <Typography color="textSecondary">
                    <strong>Subject:</strong> {teacher.subject}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </StyledPaper>
    </Box>
  );
};

export default BatchDetails;
