import React, { useState } from "react";
import { TextField, MenuItem, Button, Grid, Typography, Box } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { styled } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function QuestionCreat() {
  // State Management
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionSection, setQuestionSection] = useState("");
  const [answerSection, setAnswerSection] = useState("");

  // Handle CKEditor Changes
  const handleQuestionChange = (event, editor) => {
    const data = editor.getData();
    setQuestionSection(data);
  };

  const handleAnswerChange = (event, editor) => {
    const data = editor.getData();
    setAnswerSection(data);
  };

  // Save Functionality
  const handleSave = () => {
    const questionData = {
      class: selectedClass,
      subject: selectedSubject,
      difficulty: difficultyLevel,
      type: questionType,
      title: questionTitle,
      questionContent: questionSection,
      answerContent: answerSection,
    };

    console.log("Question Data:", questionData);

    // Add further logic for saving or submitting this data to a backend
  };

  return (
    <Box p={2}>
      {/* <Typography variant="h6" gutterBottom>
        Question Details
      </Typography> */}
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        sx={{
          marginBottom: 3,
          textTransform: "none",
          fontWeight: "bold",
          color: "#2563eb",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Back to Question List
      </Button>

      <Grid container spacing={2}>
        {/* Left Column: Details */}
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={5}>
            <TextField
              select
              label="Select a Class"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              fullWidth
            >
              <MenuItem value="11">11th Class</MenuItem>
              <MenuItem value="12">12th Class</MenuItem>
            </TextField>

            <TextField
              select
              label="Select a Subject"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              fullWidth
            >
              <MenuItem value="Physics">Physics</MenuItem>
              <MenuItem value="Hindi">Hindi</MenuItem>
            </TextField>

            <TextField
              select
              label="Select Difficulty Level"
              value={difficultyLevel}
              onChange={(e) => setDifficultyLevel(e.target.value)}
              fullWidth
            >
              <MenuItem value="Level1">Level 1</MenuItem>
              <MenuItem value="Level2">Level 2</MenuItem>
            </TextField>

            <TextField
              select
              label="Select Question Type"
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
              fullWidth
            >
              <MenuItem value="Single Choice">Single Choice</MenuItem>
              <MenuItem value="Multiple Choice">Multiple Choice</MenuItem>
            </TextField>
          </Box>
        </Grid>

        {/* Middle Column: Questions */}
        <Grid item xs={12} md={6 }>
          {/* <Typography variant="h6" gutterBot
            Input Questions
          </Typography> */}
          <Box display="flex" flexDirection="column" gap={3}>
          <TextField
              label="Question Code"
              placeholder=""
              value={questionTitle}
              onChange={(e) => setQuestionTitle(e.target.value)}
              fullWidth
              InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
            />
            <TextField
              label="Question Title"
              placeholder="Enter your question's title"
              value={questionTitle}
              onChange={(e) => setQuestionTitle(e.target.value)}
              fullWidth
              InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
            />

            
  <Box >
    <Typography variant="subtitle1" gutterBottom>
    Questions Section
    </Typography>
    <TextField
      multiline
      rows={4}
      variant="outlined"
      fullWidth
      placeholder="Add Questions Section here"
      onChange={(e) => console.log(e.target.value)} 
    />
  </Box>
  <Box >
    <Typography variant="subtitle1" gutterBottom>
   Answer Section
    </Typography>
    <TextField
      multiline
      rows={4}
      variant="outlined"
      fullWidth
      placeholder="Add Answer Section here"
      onChange={(e) => console.log(e.target.value)} // Replace with your handler
    />
  </Box>


            <TextField select label="Options Text" fullWidth>
              <MenuItem value="Option1">Option 1</MenuItem>
              <MenuItem value="Option2">Option 2</MenuItem>
            </TextField>

            <TextField select label="Number of Options" fullWidth>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="4">4</MenuItem>
            </TextField>

            <TextField select label="Answer Options" fullWidth>
              <MenuItem value="Option A">Option A</MenuItem>
              <MenuItem value="Option B">Option B</MenuItem>
            </TextField>

            <TextField
              label="Options Text Starts With"
              placeholder="Enter text prefix for options"
              fullWidth
              InputProps={{
                style: { padding: "7px" }, // Padding inside the TextField
              }}
            />
          </Box>

          <StyledButton
            fullWidth={70}
            
            sx={{ marginTop: "20px" }}
          >
            Save
          </StyledButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default QuestionCreat;


const StyledButton = styled(Button)({
    fontFamily: "Poppins",
    backgroundColor: "#7366FF",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5A52D6",
    },
  });