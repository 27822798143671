import React, { useState } from "react";
import { Box, Typography, Checkbox, Button, Avatar } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom"; // Corrected navigation import

const AdminDetails = () => {
  const navigate = useNavigate(); // useNavigate hook for navigation

  const [admin, setAdmin] = useState({
    id: 1,
    name: "John Doe",
    aliasName: "Super Admin",
    email: "john@example.com",
    number: "1234567890",
    password: "password123",
    createdAt: "2023-06-26",
    roles: {
      Dashboard: true,
      Admin: true,
      Institute: false,
      Plans: false,
      "Product & Services": true,
      Ticket: true,
      "Payment Analytics": false,
    },
  });

  const handleCheckboxChange = (role) => {
    setAdmin((prevAdmin) => ({
      ...prevAdmin,
      roles: {
        ...prevAdmin.roles,
        [role]: !prevAdmin.roles[role], // Toggle role
      },
    }));
  };

  const StyledButton = styled(Button)(() => ({
    fontFamily: "Poppins",
    backgroundColor: "#7366FF",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5A52D6",
    },
  }));

  return (
    <Box sx={{ backgroundColor: "#F5F5F5", height: "100vh" }}>
      {/* Navigation Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 2,
          padding: 2,
        }}
      >
        <StyledButton onClick={() => navigate("/admin")}>
          Admin List
        </StyledButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 4,

          padding: 3,
          fontFamily: "'Poppins', sans-serif",
          borderRadius: 2,
          // boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          marginTop: 3,
        }}
      >
        {/* Left Side: Admin Details */}
        <Box
          sx={{
            flex: 1,
            padding: 3,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Avatar
              sx={{
                backgroundColor: "#2196F3",
                width: 64,
                height: 64,
                marginRight: 2,
              }}
            >
              {admin.name.charAt(0)}
            </Avatar>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontFamily: "'Poppins', sans-serif" }}
              >
                {admin.name}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontFamily: "'Poppins', sans-serif" }}
              >
                {admin.aliasName}
              </Typography>
            </Box>
          </Box>

          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Email:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            {admin.email}
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Contact Number:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            {admin.number}
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Password:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            {admin.password}
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            Created At:
          </Typography>
          <Typography variant="body2">{admin.createdAt}</Typography>
        </Box>

        {/* Right Side: Assign Roles */}
        <Box
          sx={{
            flex: 1,
            padding: 3,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: 2,
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Assign Roles
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {Object.keys(admin.roles).map((role) => (
              <Box
                key={role}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 0,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {role}
                </Typography>
                <Checkbox
                  checked={admin.roles[role]}
                  onChange={() => handleCheckboxChange(role)}
                  color="primary"
                />
              </Box>
            ))}
          </Box>
          <StyledButton
            sx={{
              marginTop: 3,
            }}
          >
            Save Changes
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDetails;
