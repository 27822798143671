import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Drawer,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { PictureAsPdf as PdfIcon } from "@mui/icons-material";

const StyledButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#7366FF",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#5A52D6",
  },
});

const Content = () => {
    const [rows, setRows] = useState([
        {
          id: 1,
          batchname: "Batch A",
          video: "Introduction to Algebra",
          assignment: "Algebra Assignment 1",
          test: "Algebra Test 1",
          ebook: "Basic Algebra Concepts",
        },
        {
          id: 2,
          batchname: "Batch B",
          video: "Mechanics Basics",
          assignment: "Mechanics Assignment 1",
          test: "Mechanics Test 1",
          ebook: "Physics Mechanics Guide",
        },
    
      ]);
  
    
      const [searchTerm, setSearchTerm] = useState("");
      const [isDrawerOpen, setIsDrawerOpen] = useState(false);
      const [selectedRow, setSelectedRow] = useState(null);
    
      const isMobile = useMediaQuery("(max-width:600px)");
    
      // Updated filtering logic
      const filteredRows = rows.filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    
 
  const handleEditRow = (row) => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleSaveChanges = () => {
    if (selectedRow.id) {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === selectedRow.id ? selectedRow : row))
      );
    } else {
      const newId = rows.length + 1;
      setRows([
        ...rows,
        {
          ...selectedRow,
          id: newId,
          createdAt: new Date().toISOString().split("T")[0],
        },
      ]);
    }
    setSelectedRow(null);
    setIsDrawerOpen(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };
  const navigate = useNavigate()
  const handlenavigate =() =>{
    navigate('/create-batch')
  }



  const columns = [
    { field: "id", headerName: "SL", flex: 0.3 },
    { field: "batchname", headerName: "Batch Name", flex: 1 },
    {
      field: "video",
      headerName: "Video",
      flex: 1,
      renderCell: (params) => (
        <a href="https://www.youtube.com/watch?v=3JZ_D3ELwOQ" target="_blank" rel="noopener noreferrer">
          View Video
        </a>
      ),
    },
    {
      field: "assignment",  
      headerName: "Assignment", 
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <PdfIcon style={{ color: "#D32F2F" }} />
          Download Assignment
        </div>
      ),
    },
    {
      field: "test",
      headerName: "Test",
      flex: 1,
      renderCell: (params) => (
        <div  style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <PdfIcon style={{ color: "#D32F2F" }} />
          Download Test
        </div>
      ),
    },
    {
      field: "ebook",
      headerName: "eBook",
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <PdfIcon style={{ color: "#D32F2F" }} />
          Download eBook
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {/* View Icon */}
          <Link to={`/batches-content/${params.row.id}`}>
            <EyeIcon
              style={{
                width: "20px",
                height: "20px",
                color: "#2563eb",
                cursor: "pointer",
              }}
              title="View Details"
            />
          </Link>
  
          {/* Edit Icon */}
          <PencilSquareIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#10b981",
              cursor: "pointer",
            }}
            title="Edit"
          />
  
          {/* Delete Icon */}
          <TrashIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#ef4444",
              cursor: "pointer",
            }}
            title="Delete"
          />
        </Box>
      ),
    },
  ];

  return (
    <div
      style={{
        fontFamily: "Poppins",
        padding: isMobile ? "10px" : "15px",
      }}
    >
       <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2b3674",
          }}
        >
         Content
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: isMobile ? "100%" : 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlassIcon
                    style={{ width: "20px", height: "20px", color: "#7366FF" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            onClick={()=> {  setIsDrawerOpen(true);}}
            sx={{ width: isMobile ? "100%" : "auto" }}
            
          >
            Create Content
          </StyledButton>
        </Box>
      </Box>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={
            isMobile ? columns.filter((col) => col.field !== "number") : columns
          }
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div
          style={{
            width: isMobile ? "100vw" : 400,
            padding: "20px",
            fontFamily: "Poppins",
          }}
        >
          <h3>{selectedRow?.id ? "Edit Admin" : "Add Content"}</h3>
          <FormControl fullWidth margin="normal">
  <InputLabel>Select Batch Name</InputLabel>
  <Select
    value={selectedRow?.batchname || ""}
    onChange={(e) => handleInputChange("batchname", e.target.value)}
    size="small"
    variant="outlined"
    sx={{
      padding: "7px", // Add padding here
    }}
  >
    <MenuItem value="Batch A">Batch A</MenuItem>
    <MenuItem value="Batch B">Batch B</MenuItem>
    <MenuItem value="Batch C">Batch C</MenuItem>
    <MenuItem value="Batch D">Batch D</MenuItem>
    <MenuItem value="Batch E">Batch E</MenuItem>
  </Select>
</FormControl>

{/* Video Upload */}
<Typography variant="subtitle1" sx={{ mt: 2 }}>
  Add Video URL
</Typography>
<TextField
  fullWidth
  variant="outlined"
  placeholder="Enter video URL"
  size="small"
  value={selectedRow?.videoURL || ""}
  onChange={(e) => handleInputChange("videoURL", e.target.value)}
  sx={{
    marginTop: "10px",
  }}
/>

{/* Assignment PDF Upload */}
<Typography variant="subtitle1" sx={{ mt: 2 }}>
  Upload Assignment PDF
</Typography>
<Button
  variant="outlined"
  fullWidth
  onClick={() => document.getElementById("assignment-upload").click()}
>
  Choose Assignment File
</Button>
<input
  id="assignment-upload"
  type="file"
  accept="application/pdf"
  style={{ display: "none" }}
  onChange={(e) => handleInputChange("assignment", e.target.files[0])}
/>

{/* eBook PDF Upload */}
<Typography variant="subtitle1" sx={{ mt: 2 }}>
  Upload eBook PDF
</Typography>
<Button
  variant="outlined"
  fullWidth
  onClick={() => document.getElementById("ebook-upload").click()}
>
  Choose eBook File
</Button>
<input
  id="ebook-upload"
  type="file"
  accept="application/pdf"
  style={{ display: "none" }}
  onChange={(e) => handleInputChange("ebook", e.target.files[0])}
/>

{/* Text PDF Upload */}
<Typography variant="subtitle1" sx={{ mt: 2 }}>
  Upload Test PDF
</Typography>
<Button
  variant="outlined"
  fullWidth
  onClick={() => document.getElementById("text-upload").click()}
>
  Choose Test File
</Button>
<input
  id="text-upload"
  type="file"
  accept="application/pdf"
  style={{ display: "none" }}
  onChange={(e) => handleInputChange("text", e.target.files[0])}
/>
          <StyledButton
            fullWidth
            onClick={handleSaveChanges}
            sx={{ marginTop: "20px" }}
          >
            Save Changes
          </StyledButton>
        </div>
      </Drawer>
    </div>
  );
};

export default Content;

