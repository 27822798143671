import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Drawer,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";

const StyledButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#7366FF",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#5A52D6",
  },
});

const QuestionManagment = () => {
    const [rows, setRows] = useState([
        {
          id: 1,
          questioncode: "Q001",
          class: "12",
          subject: "Mathematics",
          difficultylevel: "Level 1",
          questiontype: "Multiple Choice",
          questionlanguage: "English",
          questiontitle: "What is the value of π?",
        },
        {
          id: 2,
          questioncode: "Q002",
          class: "12",
          subject: "Science",
          difficultylevel: "Level 2",
          questiontype: "Multiple Choice",
          questionlanguage: "English",
          questiontitle: "The boiling point of water is 100°C.",
        },
        // Add more rows...
      ]);
    
      const [searchTerm, setSearchTerm] = useState("");
      const [isDrawerOpen, setIsDrawerOpen] = useState(false);
      const [selectedRow, setSelectedRow] = useState(null);
    
      const isMobile = useMediaQuery("(max-width:600px)");
    
      // Updated filtering logic
      const filteredRows = rows.filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    
 
  const handleEditRow = (row) => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleSaveChanges = () => {
    if (selectedRow.id) {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === selectedRow.id ? selectedRow : row))
      );
    } else {
      const newId = rows.length + 1;
      setRows([
        ...rows,
        {
          ...selectedRow,
          id: newId,
          createdAt: new Date().toISOString().split("T")[0],
        },
      ]);
    }
    setSelectedRow(null);
    setIsDrawerOpen(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };
  const navigate = useNavigate()
  const handlenavigate =() =>{
    navigate('/question-create')
  }

  const columns = [
        { field: "id", headerName: "SL", flex: 0.3 },
        { field: "questioncode", headerName: "Question Code", flex: 1 },
        { field: "class", headerName: "Class", flex: 1 },
        { field: "subject", headerName: "Subject", flex: 1 },
        { field: "difficultylevel", headerName: "Difficulty Level", flex: 1 },
        { field: "questiontype", headerName: "Question Type", flex: 1 },
        { field: "questionlanguage", headerName: "Question Language", flex: 1 },
        // { field: "questiontitle", headerName: "Question Title", flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {/* View Icon */}
                <Link to={`/question/${params.row.id}`}>
                  <EyeIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#2563eb",
                      cursor: "pointer",
                    }}
                    title="View Details"
                  />
                </Link>
          
                {/* Edit Icon */}
                <PencilSquareIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#10b981",
                    cursor: "pointer",
                  }}
                  title="Edit"
              
                />
          
                {/* Delete Icon */}
                <TrashIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#ef4444",
                    cursor: "pointer",
                  }}
                  title="Delete"
                
                />
              </Box>
            ),
          },
  ];

  return (
    <div
      style={{
        fontFamily: "Poppins",
        padding: isMobile ? "10px" : "15px",
      }}
    >
       <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2b3674",
          }}
        >
          Question Management
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: isMobile ? "100%" : 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlassIcon
                    style={{ width: "20px", height: "20px", color: "#7366FF" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            onClick={handlenavigate}
            sx={{ width: isMobile ? "100%" : "auto" }}
          >
            Create Question
          </StyledButton>
        </Box>
      </Box>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={
            isMobile ? columns.filter((col) => col.field !== "number") : columns
          }
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div
          style={{
            width: isMobile ? "100vw" : 400,
            padding: "20px",
            fontFamily: "Poppins",
          }}
        >
          <h3>{selectedRow?.id ? "Edit Admin" : "Add New Admin"}</h3>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.name || ""}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
          <TextField
            label="Alias Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.aliasName || ""}
            onChange={(e) => handleInputChange("aliasName", e.target.value)}
          />
          <TextField
            label="Number"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.number || ""}
            onChange={(e) => handleInputChange("number", e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            margin="normal"
            value={selectedRow?.password || ""}
            onChange={(e) => handleInputChange("password", e.target.value)}
          />
          <TextField
            label="Subject"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.subject || ""}
            onChange={(e) => handleInputChange("subject", e.target.value)}
          />
          <TextField
            label="Experience"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.experience || ""}
            onChange={(e) => handleInputChange("experience", e.target.value)}
          />
          <TextField
            label="Address"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.address || ""}
            onChange={(e) => handleInputChange("address", e.target.value)}
          />
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedRow?.roles || []}
              onChange={(e) => handleInputChange("roles", e.target.value)}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      style={{
                        backgroundColor: "#7366FF",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
              )}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Teacher">Teacher</MenuItem>
            </Select>
          </FormControl>
          <StyledButton
            fullWidth
            onClick={handleSaveChanges}
            sx={{ marginTop: "20px" }}
          >
            Save Changes
          </StyledButton>
        </div>
      </Drawer>
    </div>
  );
};

export default QuestionManagment;
