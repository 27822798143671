import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Drawer,
  TextField,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#7366FF",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#5A52D6",
  },
});

const TCreationlist = () => {
  const [rows, setRows] = useState([
    {
      id: 1,
      testName: "Math Test 1",
      testTemplateName: "Algebra Basics",
      subject: "Math",
      marks: 60,
      duration: 100,
    },
    {
      id: 2,
      testName: "Physics Quiz",
      testTemplateName: "Mechanics",
      subject: "Physics",
      marks: 45,
      duration: 50,
    },
    {
      id: 3,
      testName: "English Mock Test",
      testTemplateName: "Grammar and Composition",
      subject: "English",
      marks: 90,
      duration: 200,
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigation = useNavigate();

  const filteredRows = rows.filter((row) =>
    row.testName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setIsDrawerOpen(true);
  };

  const handleSaveChanges = () => {
    if (selectedRow.id) {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === selectedRow.id ? selectedRow : row))
      );
    } else {
      const newId = rows.length + 1;
      setRows([
        ...rows,
        {
          ...selectedRow,
          id: newId,
        },
      ]);
    }
    setSelectedRow(null);
    setIsDrawerOpen(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: value,
    }));
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    { field: "testName", headerName: "Test Name", flex: 1 },
    { field: "testTemplateName", headerName: "Test Template Name", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "marks", headerName: "Marks", flex: 1 },
    { field: "duration", headerName: "Duration", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <PencilSquareIcon
          style={{
            marginTop:"20px",
            width: "20px",
            height: "20px",
            color: "#10b981",
            cursor: "pointer",
          }}
          title="Edit"
            onClick={() => handleEditRow(params.row)}
            sx={{
              color: "#2563eb",
              cursor: "pointer",
            }}
          />
           
          <TrashIcon
         style={{
          marginTop:"20px",
          width: "20px",
          height: "20px",
          color: "#ef4444",
          cursor: "pointer",
        }}
        title="Delete"
            onClick={() =>
              setRows((prevRows) =>
                prevRows.filter((row) => row.id !== params.row.id)
              )
            }
            sx={{
              color: "#FF4D4F",
              cursor: "pointer",
            }}
          />
          
       
        </Box>
      ),
      sortable: false,
    },
  ];

  return (
    <div
      style={{
        fontFamily: "Poppins",
        padding: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2b3674",
          }}
        >
          Test Creation
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            placeholder="Search test name..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlassIcon
                    style={{ width: "20px", height: "20px", color: "#7366FF" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            onClick={() => {
              setSelectedRow({
                testName: "",
                testTemplateName: "",
                subject: "",
                marks: "",
                duration: "",
              });
              navigation("/TCreations");
            }}
          >
            Add Test
          </StyledButton>
        </Box>
      </Box>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div
          style={{
            width: 400,
            padding: "20px",
            fontFamily: "Poppins",
          }}
        >
          <h3>{selectedRow?.id ? "Edit Test" : "Add New Test"}</h3>
          <TextField
            label="Test Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.testName || ""}
            onChange={(e) => handleInputChange("testName", e.target.value)}
          />
          <TextField
            label="Test Template Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.testTemplateName || ""}
            onChange={(e) =>
              handleInputChange("testTemplateName", e.target.value)
            }
          />
          <TextField
            label="Subject"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={selectedRow?.subject || ""}
            onChange={(e) => handleInputChange("subject", e.target.value)}
          />
          <TextField
            label="Marks"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            type="number"
            value={selectedRow?.marks || ""}
            onChange={(e) => handleInputChange("marks", e.target.value)}
          />
          <TextField
            label="Duration"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            type="number"
            value={selectedRow?.duration || ""}
            onChange={(e) => handleInputChange("duration", e.target.value)}
          />
          <StyledButton
            fullWidth
            onClick={handleSaveChanges}
            sx={{ marginTop: "20px" }}
          >
            Save Test
          </StyledButton>
        </div>
      </Drawer>
    </div>
  );
};

export default TCreationlist;
